@charset "UTF-8";
@import url(../../bower_components/foundation-sites/dist/css/foundation.min.css);
@import url(https://fonts.googleapis.com/css?family=Eczar:400,500,600,700,800|Work+Sans:100,200,300,400,500,600,700,800,900);
@font-face {
  font-family: "SSStandardRegular";
  src: url("../../fonts/ss-standard/ss-standard.eot");
  src: url("../../fonts/ss-standard/ss-standard.eot?#iefix") format("embedded-opentype"), url("../../fonts/ss-standard/ss-standard.woff") format("woff"), url("../../fonts/ss-standard/ss-standard.ttf") format("truetype"), url("../../fonts/ss-standard/ss-standard.svg#SSStandardRegular") format("svg");
  font-weight: normal;
  font-style: normal; }

.ss-icon, [class^="ss-"]:before, [class*=" ss-"]:before {
  font-family: "SSStandardRegular";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -webkit-font-feature-settings: "liga", "dlig";
  -moz-font-feature-settings: "liga=1, dlig=1";
  -moz-font-feature-settings: "liga", "dlig";
  -ms-font-feature-settings: "liga", "dlig";
  -o-font-feature-settings: "liga", "dlig";
  font-feature-settings: "liga", "dlig"; }

[class^="ss-"]:before, [class*=" ss-"]:before {
  display: inline-block; }

.ss-cursor:before {
  content: '\E001'; }

.ss-crosshair:before {
  content: '\2316'; }

.ss-search:before {
  content: '\D83D\DD0E'; }

.ss-view:before {
  content: '\D83D\DC40'; }

.ss-attach:before {
  content: '\D83D\DCCE'; }

.ss-link:before {
  content: '\D83D\DD17'; }

.ss-move:before {
  content: '\E070'; }

.ss-write:before {
  content: '\270E'; }

.ss-writingdisabled:before {
  content: '\E071'; }

.ss-erase:before {
  content: '\2710'; }

.ss-compose:before {
  content: '\D83D\DCDD'; }

.ss-lock:before {
  content: '\D83D\DD12'; }

.ss-unlock:before {
  content: '\D83D\DD13'; }

.ss-key:before {
  content: '\D83D\DD11'; }

.ss-backspace:before {
  content: '\232B'; }

.ss-ban:before {
  content: '\D83D\DEAB'; }

.ss-trash:before {
  content: '\E0D0'; }

.ss-target:before {
  content: '\25CE'; }

.ss-tag:before {
  content: '\E100'; }

.ss-bookmark:before {
  content: '\D83D\DD16'; }

.ss-flag:before {
  content: '\2691'; }

.ss-like:before {
  content: '\D83D\DC4D'; }

.ss-dislike:before {
  content: '\D83D\DC4E'; }

.ss-heart:before {
  content: '\2665'; }

.ss-halfheart:before {
  content: '\E1A0'; }

.ss-star:before {
  content: '\22C6'; }

.ss-halfstar:before {
  content: '\E1A1'; }

.ss-sample:before {
  content: '\E200'; }

.ss-crop:before {
  content: '\E201'; }

.ss-layers:before {
  content: '\E202'; }

.ss-phone:before {
  content: '\D83D\DCDE'; }

.ss-phonedisabled:before {
  content: '\E300'; }

.ss-rss:before {
  content: '\E310'; }

.ss-facetime:before {
  content: '\E320'; }

.ss-reply:before {
  content: '\21A9'; }

.ss-mail:before {
  content: '\2709'; }

.ss-inbox:before {
  content: '\D83D\DCE5'; }

.ss-chat:before {
  content: '\D83D\DCAC'; }

.ss-user:before {
  content: '\D83D\DC64'; }

.ss-femaleuser:before {
  content: '\D83D\DC67'; }

.ss-cart:before {
  content: '\E500'; }

.ss-creditcard:before {
  content: '\D83D\DCB3'; }

.ss-barchart:before {
  content: '\D83D\DCCA'; }

.ss-piechart:before {
  content: '\E570'; }

.ss-box:before {
  content: '\D83D\DCE6'; }

.ss-home:before {
  content: '\2302'; }

.ss-globe:before {
  content: '\D83C\DF0E'; }

.ss-navigate:before {
  content: '\E670'; }

.ss-compass:before {
  content: '\E671'; }

.ss-signpost:before {
  content: '\E672'; }

.ss-location:before {
  content: '\E6D0'; }

.ss-pin:before {
  content: '\D83D\DCCD'; }

.ss-music:before {
  content: '\266B'; }

.ss-mic:before {
  content: '\D83C\DFA4'; }

.ss-volume:before {
  content: '\D83D\DD08'; }

.ss-volumelow:before {
  content: '\D83D\DD09'; }

.ss-volumehigh:before {
  content: '\D83D\DD0A'; }

.ss-airplay:before {
  content: '\E800'; }

.ss-camera:before {
  content: '\D83D\DCF7'; }

.ss-picture:before {
  content: '\D83C\DF04'; }

.ss-video:before {
  content: '\D83D\DCF9'; }

.ss-play:before {
  content: '\25B6'; }

.ss-pause:before {
  content: '\E8A0'; }

.ss-stop:before {
  content: '\25A0'; }

.ss-record:before {
  content: '\25CF'; }

.ss-rewind:before {
  content: '\23EA'; }

.ss-fastforward:before {
  content: '\23E9'; }

.ss-skipback:before {
  content: '\23EE'; }

.ss-skipforward:before {
  content: '\23ED'; }

.ss-eject:before {
  content: '\23CF'; }

.ss-repeat:before {
  content: '\D83D\DD01'; }

.ss-replay:before {
  content: '\21BA'; }

.ss-shuffle:before {
  content: '\D83D\DD00'; }

.ss-notebook:before {
  content: '\D83D\DCD3'; }

.ss-grid:before {
  content: '\E9A0'; }

.ss-rows:before {
  content: '\E9A1'; }

.ss-desktop:before {
  content: '\D83D\DCBB'; }

.ss-laptop:before {
  content: '\EA00'; }

.ss-tablet:before {
  content: '\EA01'; }

.ss-cell:before {
  content: '\D83D\DCF1'; }

.ss-battery:before {
  content: '\D83D\DD0B'; }

.ss-batteryhigh:before {
  content: '\EA10'; }

.ss-batterymedium:before {
  content: '\EA11'; }

.ss-batterylow:before {
  content: '\EA12'; }

.ss-batteryempty:before {
  content: '\EA13'; }

.ss-clouddownload:before {
  content: '\EB00'; }

.ss-download:before {
  content: '\EB01'; }

.ss-cloudupload:before {
  content: '\EB40'; }

.ss-upload:before {
  content: '\EB41'; }

.ss-fork:before {
  content: '\EB80'; }

.ss-merge:before {
  content: '\EB81'; }

.ss-refresh:before {
  content: '\21BB'; }

.ss-sync:before {
  content: '\EB82'; }

.ss-loading:before {
  content: '\EB83'; }

.ss-wifi:before {
  content: '\EB84'; }

.ss-connection:before {
  content: '\EB85'; }

.ss-file:before {
  content: '\D83D\DCC4'; }

.ss-folder:before {
  content: '\D83D\DCC1'; }

.ss-quote:before {
  content: '\201C'; }

.ss-text:before {
  content: '\ED00'; }

.ss-font:before {
  content: '\ED01'; }

.ss-list:before {
  content: '\ED50'; }

.ss-layout:before {
  content: '\EDA0'; }

.ss-action:before {
  content: '\EE00'; }

.ss-expand:before {
  content: '\2922'; }

.ss-contract:before {
  content: '\EE01'; }

.ss-info:before {
  content: '\2139'; }

.ss-alert:before {
  content: '\26A0'; }

.ss-caution:before {
  content: '\26D4'; }

.ss-plus:before {
  content: '\002B'; }

.ss-hyphen:before {
  content: '\002D'; }

.ss-dropdown:before {
  content: '\25BE'; }

.ss-check:before {
  content: '\2713'; }

.ss-delete:before {
  content: '\2421'; }

.ss-settings:before {
  content: '\2699'; }

.ss-dashboard:before {
  content: '\F000'; }

.ss-notifications:before {
  content: '\D83D\DD14'; }

.ss-notificationsdisabled:before {
  content: '\D83D\DD15'; }

.ss-clock:before {
  content: '\23F2'; }

.ss-stopwatch:before {
  content: '\23F1'; }

.ss-calendar:before {
  content: '\D83D\DCC6'; }

.ss-calendaradd:before {
  content: '\F070'; }

.ss-calendarremove:before {
  content: '\F071'; }

.ss-calendarcheck:before {
  content: '\F072'; }

.ss-briefcase:before {
  content: '\D83D\DCBC'; }

.ss-cloud:before {
  content: '\2601'; }

.ss-navigateup:before {
  content: '\F500'; }

.ss-navigateright:before {
  content: '\25BB'; }

.ss-navigatedown:before {
  content: '\F501'; }

.ss-navigateleft:before {
  content: '\25C5'; }

.ss-up:before {
  content: '\2B06'; }

.ss-upright:before {
  content: '\2B08'; }

.ss-right:before {
  content: '\27A1'; }

.ss-downright:before {
  content: '\2B0A'; }

.ss-down:before {
  content: '\2B07'; }

.ss-downleft:before {
  content: '\2B0B'; }

.ss-left:before {
  content: '\2B05'; }

.ss-upleft:before {
  content: '\2B09'; }

.ss-retweet:before {
  content: '\F600'; }

@font-face {
  font-family: "SSSocial";
  src: url("../../fonts/ss-social/ss-social-circle.eot");
  src: url("../../fonts/ss-social/ss-social-circle.eot?#iefix") format("embedded-opentype"), url("../../fonts/ss-social/ss-social-circle.woff") format("woff"), url("../../fonts/ss-social/ss-social-circle.ttf") format("truetype"), url("../../fonts/ss-social/ss-social-circle.svg#SSSocialCircle") format("svg");
  font-weight: normal;
  font-style: normal; }

.ss-social, .ss-icon.ss-social-circle,
[class^="ss-"]:before, [class*=" ss-"]:before,
[class^="ss-"][class*=" ss-social"]:before, [class*=" ss-"][class*=" ss-social"]:before,
[class^="ss-"].right:after, [class*=" ss-"].right:after,
[class^="ss-"][class*=" ss-social"].right:after, [class*=" ss-"][class*=" ss-social"].right:after {
  font-family: "SSSocial";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased; }

.ss-social-circle,
[class^="ss-"].ss-social-circle:before, [class*=" ss-"].ss-social-circle:before,
[class^="ss-"].ss-social-circle.right:after, [class*=" ss-"].ss-social-circle.right:after {
  font-weight: bold; }

[class^="ss-"].right:before, [class^="ss-"][class*=" ss-social"].right:before {
  display: none;
  content: ''; }

.ss-facebook:before, .ss-facebook.right:after {
  content: ''; }

.ss-twitter:before, .ss-twitter.right:after {
  content: ''; }

.ss-linkedin:before, .ss-linkedin.right:after {
  content: ''; }

.ss-googleplus:before, .ss-googleplus.right:after {
  content: ''; }

.ss-tumblr:before, .ss-tumblr.right:after {
  content: ''; }

.ss-wordpress:before, .ss-wordpress.right:after {
  content: ''; }

.ss-blogger:before, .ss-blogger.right:after {
  content: ''; }

.ss-posterous:before, .ss-posterous.right:after {
  content: ''; }

.ss-youtube:before, .ss-youtube.right:after {
  content: ''; }

.ss-vimeo:before, .ss-vimeo.right:after {
  content: ''; }

.ss-flickr:before, .ss-flickr.right:after {
  content: ''; }

.ss-instagram:before, .ss-instagram.right:after {
  content: ''; }

.ss-pinterest:before, .ss-pinterest.right:after {
  content: ''; }

.ss-dribbble:before, .ss-dribbble.right:after {
  content: ''; }

.ss-behance:before, .ss-behance.right:after {
  content: ''; }

.ss-github:before, .ss-github.right:after {
  content: ''; }

.ss-octocat:before, .ss-octocat.right:after {
  content: ''; }

.ss-paypal:before, .ss-paypal.right:after {
  content: ''; }

.ss-foursquare:before, .ss-foursquare.right:after {
  content: ''; }

.ss-skype:before, .ss-skype.right:after {
  content: ''; }

.ss-rdio:before, .ss-rdio.right:after {
  content: ''; }

.ss-spotify:before, .ss-spotify.right:after {
  content: ''; }

.ss-lastfm:before, .ss-lastfm.right:after {
  content: ''; }

.ss-mail:before, .ss-mail.right:after {
  content: '✉'; }

.ss-like:before, .ss-like.right:after {
  content: '👍'; }

.ss-rss:before, .ss-rss.right:after {
  content: ''; }

body {
  background-repeat: repeat;
  background-image: url(../../img/shared/brundage-sitework-metal.png); }

p {
  font-size: 1.125rem;
  font-family: 'Work Sans', sans-serif; }

.m-0 {
  margin: 0px; }

.p-0 {
  padding: 0px; }

.hours {
  text-align: center; }
  .hours h1, .hours header h1 {
    font-family: 'Work Sans', sans-serif;
    font-size: 1.5rem;
    color: #807ffe;
    padding-top: 25px; }
  .hours p {
    font-family: 'Work Sans', sans-serif;
    font-style: italic;
    color: #122a4c; }

.hours-footer {
  padding-bottom: 10px; }

@media screen and (max-width: 45.0625em) {
  .hide-on-small {
    display: none; } }

.bhr-banner {
  padding: 5px;
  background-color: #008CBA;
  color: #fff;
  display: none; }
  .bhr-banner a {
    text-decoration: underline;
    color: #fff; }

a.ss-social {
  font-size: 1.5rem;
  top: 6px;
  position: relative; }

.logo-svg-nav-lg {
  fill: #807ffe;
  stroke: #807ffe; }

.wedding-button {
  color: #fff; }

.request-info {
  margin-bottom: 50px; }

form input[type=submit] {
  color: #fff;
  background-color: #807ffe;
  border: none;
  padding: 8px;
  font-family: 'Work Sans', sans-serif;
  margin-bottom: 40px; }

form label {
  font-size: 1.125rem; }

form .heading, form .heading label {
  font-size: 1.25rem;
  margin-top: 30px;
  font-weight: 700;
  color: #393b6b; }

form .instructions {
  font-size: 1rem;
  font-style: italic;
  font-weight: 100;
  color: #777; }

#thank-you {
  margin-top: 40px;
  margin-bottom: 40px; }
  #thank-you h1 {
    font-family: 'Work Sans', sans-serif;
    text-align: center; }
  #thank-you p {
    font-family: 'Work Sans', sans-serif; }
  #thank-you a {
    color: #807ffe; }

.sitewide-banner {
  display: none !important;
  background-color: rgba(18, 42, 76, 0.75); }
  .sitewide-banner p {
    margin-bottom: 0;
    color: #fff;
    text-align: center;
    font-family: 'Work Sans', sans-serif; }
    .sitewide-banner p a {
      color: #fff; }
      .sitewide-banner p a:hover {
        text-decoration: underline; }
  .sitewide-banner .close-button {
    right: 1rem;
    top: -.125rem;
    font-size: 1.5em;
    line-height: 1;
    color: #fff; }

.easter-button {
  display: none;
  position: relative;
  top: 30px;
  background-color: #807ffe;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 1.125rem; }
  .easter-button:hover {
    color: #fff;
    opacity: .8; }
  .easter-button .close-button {
    right: 1rem;
    top: -.125rem;
    font-size: 1.5em;
    line-height: 1;
    color: #fff; }

#easterMenu {
  text-align: center; }
  #easterMenu h1, #easterMenu h2, #easterMenu h3, #easterMenu h4, #easterMenu h5, #easterMenu h6 {
    color: #393b6b; }
  #easterMenu h1 {
    font-family: 'Eczar', serif; }
  #easterMenu h2 {
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 30px; }
  #easterMenu h3 {
    font-family: 'Work Sans', sans-serif;
    margin-top: 20px; }
  #easterMenu p {
    font-family: 'Eczar', serif; }
  #easterMenu .wedding-button {
    background-color: #807ffe; }

@media screen and (min-width: 90em) {
  .xlarge-2 {
    width: 50%; } }

/* Variables */
@media screen and (max-width: 39.9375em) {
  .menu-text {
    padding: 0px; } }

.floatL {
  float: left; }

.w-100 {
  width: 100px; }

.title-bar .menu-icon {
  z-index: 50;
  padding-bottom: 30px; }

#main-menu {
  position: relative; }
  @media screen and (min-width: 40em) {
    #main-menu {
      top: 15px; } }

.is-accordion-submenu {
  padding-left: 10px; }

/* Small Navigation */
.logo-small {
  display: none; }

.title-bar {
  padding: 0 .5rem; }

.title-bar:after {
  display: none; }

.menu-icon:after {
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
  background-color: #cacaca;
  z-index: 100; }

.menu-icon,
.title-bar-title {
  position: relative;
  top: 15px;
  float: right; }

.title-bar {
  background: none; }

.title-bar-title {
  display: none; }

.menu-text a {
  position: relative;
  z-index: 10; }

.menu-text h1 {
  font-family: 'Eczar', serif;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 1.25rem !important;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #214192; }

.menu.vertical > li {
  font-size: 1.5rem; }

/* Medium-Up Navigation */
@media only screen and (min-width: 40rem) {
  .logo-wrapper {
    position: relative; }
    .logo-wrapper .logo {
      width: 70px;
      height: 92px;
      position: absolute;
      left: 50%;
      right: 50%;
      top: -4px;
      margin-left: -35px; }
      .logo-wrapper .logo:hover {
        opacity: .75;
        transition: all 0.5s ease-in-out; } }

.top-bar .menu > li > a {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.sticky-topbar {
  width: 100%;
  z-index: 5;
  background: #fff;
  background-image: url(../../img/shared/brundage-sitework-metal.png); }

ul#main-menu ul.is-dropdown-submenu {
  background: #214192; }

ul#main-menu li {
  font-family: 'Eczar', serif;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600; }
  ul#main-menu li a {
    color: #b3afaf; }
    ul#main-menu li a:hover {
      color: #214192;
      transition: all 0.2s ease-in-out; }

ul#main-menu li.highlight a span {
  border-bottom: 3px solid #214192;
  color: #214192; }
  ul#main-menu li.highlight a span:hover {
    color: rgba(33, 65, 146, 0.5);
    transition: all 0.2s ease-in-out; }

ul#main-menu li.is-submenu-item a span {
  color: #b3afaf;
  border-bottom: 0px; }
  ul#main-menu li.is-submenu-item a span:hover {
    color: rgba(179, 175, 175, 0.5);
    transition: all 0.2s ease-in-out; }

ul#main-menu li.is-submenu-item.highlight a span {
  color: #fff;
  text-decoration: underline; }

ul#main-menu li.is-accordion-submenu-item.highlight a span {
  color: #214192;
  text-decoration: underline; }

.top-bar {
  top: 0px !important; }

.dropdown.menu > li.is-dropdown-submenu-parent > a:after {
  border-top-color: #b3afaf; }

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a:after, .is-accordion-submenu-parent > a:after {
  border-color: #214192 transparent transparent; }

footer {
  margin-top: 30px;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: rgba(33, 65, 146, 0.75); }
  footer .normal, footer .normal a {
    color: #999; }
  footer .normal a:hover {
    color: #fff !important;
    text-decoration: none;
    transition: all 0.2s ease-in-out; }
  footer p, footer a, footer p a, footer a p, footer a#addy, footer #hrs, footer h4 {
    font-family: 'Eczar', serif;
    text-transform: uppercase;
    color: #fff; }
  footer h4 {
    padding-top: 20px; }
    @media screen and (max-width: 45.0625em) {
      footer h4 {
        font-size: 1.5rem; } }
  footer a:hover, footer p a:hover, footer a p:hover, footer i.fa:hover {
    color: #999 !important;
    text-decoration: none;
    transition: all 0.2s ease-in-out; }
  footer a#addy {
    font-weight: 400; }
    footer a#addy:hover {
      color: #999 !important;
      transition: all 0.2s ease-in-out; }
  footer a:hover {
    color: #999 !important;
    transition: all 0.2s ease-in-out; }
  footer .fa {
    color: #fff; }
  footer div#art {
    position: absolute;
    bottom: 278px;
    opacity: .5; }
    @media screen and (max-width: 45.0625em) {
      footer div#art {
        bottom: 350px; } }
  footer p#copyright {
    margin-bottom: 20px; }
  footer p {
    margin-bottom: 0px; }
  footer .column-block {
    margin-bottom: 1rem; }
  footer .footer-links {
    position: relative;
    z-index: 1; }
  footer span#footer-home {
    font-size: .75rem; }
  footer a#home-footer-link {
    border-bottom: 1px solid #fff;
    margin-bottom: 5px; }

.home-content {
  position: relative; }

#home-heading .phone {
  font-weight: 800;
  font-size: 2rem; }
  #home-heading .phone a {
    color: white; }
  #home-heading .phone i {
    top: 4px;
    position: relative;
    font-size: 2.25rem; }

#home-heading section#logo-section {
  margin-bottom: 50px;
  position: relative; }

#home-heading div#logo {
  text-align: center;
  margin-top: 10px; }
  #home-heading div#logo img {
    width: 550px; }
    @media screen and (min-width: 75em) {
      #home-heading div#logo img {
        width: 750px; } }
  @media screen and (orientation: landscape) {
    #home-heading div#logo img.phone {
      width: 250px; } }

#sub-section {
  background: #214192;
  background-image: url(../../img/shared/brundage-sitework-metal.png); }

#home-sub-heading {
  padding-bottom: 25px; }

h2#home-sub {
  font-size: 1.75rem;
  line-height: 2.75rem;
  margin-top: 50px;
  font-family: 'Eczar', serif;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #fff; }
  h2#home-sub span.sub-sub {
    font-family: 'Work Sans', sans-serif;
    font-weight: 900;
    font-size: 3rem; }
  @media screen and (min-width: 45.125em) and (max-width: 63.9375em) {
    h2#home-sub {
      font-size: 3rem;
      margin-bottom: 50px;
      margin-top: 30px; } }
  @media print, screen and (min-width: 64em) {
    h2#home-sub {
      font-size: 2.75rem;
      margin-top: 30px; } }

h3 {
  color: #fff;
  font-family: 'Eczar', serif;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 700; }

section.home-cta {
  font-size: 2rem; }
  @media screen and (min-width: 45.125em) {
    section.home-cta {
      margin-top: 20px; }
      section.home-cta a.secondary {
        margin-right: 50px; } }
  section.home-cta.phone {
    margin-top: 50px; }
    @media screen and (orientation: landscape) {
      section.home-cta.phone {
        margin-top: -40px; } }

div#cta {
  margin-top: 30px;
  margin-bottom: 30px; }
  @media screen and (min-width: 75em) {
    div#cta {
      margin-top: 60px; } }
  div#cta .column-block {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  div#cta .ctas {
    color: #fff;
    text-align: center;
    position: relative; }
    div#cta .ctas a {
      display: block;
      color: #fff; }
    div#cta .ctas img.cta-icon {
      height: 70px; }
    div#cta .ctas h3 {
      font-family: 'Work Sans', sans-serif;
      color: #214192;
      text-align: center;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.75rem;
      padding-top: 10px; }
      @media screen and (max-width: 45.0625em) {
        div#cta .ctas h3 {
          font-size: 1.5rem;
          padding-bottom: 20px; } }
    div#cta .ctas p {
      font-family: 'Lora', serif;
      font-size: 1.25rem;
      text-align: center;
      padding: 10px; }
      @media print, screen and (min-width: 45.125em) {
        div#cta .ctas p {
          padding: 20px; } }
      @media screen and (max-width: 45.0625em) {
        div#cta .ctas p {
          font-size: 1.5rem; } }
    div#cta .ctas a.button {
      background-color: rgba(128, 127, 254, 0.65);
      border-color: #fff;
      font-family: 'Lora', serif !important;
      font-size: 1.125rem; }
      div#cta .ctas a.button:hover {
        background-color: #807ffe; }
      @media print, screen and (min-width: 45.125em) {
        div#cta .ctas a.button {
          position: absolute;
          bottom: 60px; } }
    div#cta .ctas .container {
      width: 75%;
      margin: 0 auto; }
      @media screen and (min-width: 75em) {
        div#cta .ctas .container {
          width: 150px; } }
    div#cta .ctas .circle {
      background-color: rgba(170, 170, 170, 0.42);
      border-radius: 50%;
      border: 1px solid #9C9C9C;
      position: relative;
      padding: 20px; }
    div#cta .ctas .barrow {
      padding: 10px; }
      div#cta .ctas .barrow img {
        left: -2px;
        position: relative; }
    div#cta .ctas .faucet {
      padding: 15px; }
      div#cta .ctas .faucet img {
        position: relative;
        top: 5px; }
  div#cta #water {
    border-left-width: 0px;
    border-right-width: 0px; }
    @media print, screen and (min-width: 45.125em) {
      div#cta #water a.button {
        margin-left: -10%; } }
  div#cta #septic {
    background-position: 0 -100px;
    border-left-width: 0px;
    border-right-width: 0px; }
    @media print, screen and (min-width: 45.125em) {
      div#cta #septic a.button {
        margin-left: -14%; } }
  div#cta #scapes {
    border-left-width: 0px;
    border-right-width: 0px; }
    @media print, screen and (min-width: 45.125em) {
      div#cta #scapes a.button {
        margin-left: -13%; } }
  div#cta #materials {
    border-left-width: 0px;
    border-right-width: 0px; }
    @media print, screen and (min-width: 45.125em) {
      div#cta #materials a.button {
        margin-left: -13%; } }

.arrow {
  position: relative;
  margin: 0 auto;
  width: 45px;
  height: 24px;
  background-size: contain; }

.arrow-row {
  position: absolute;
  bottom: 20px;
  margin-left: 44%; }
  @media print, screen and (min-width: 45.125em) {
    .arrow-row {
      margin-left: 48%;
      bottom: 40px; } }

#home-heading {
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(../../img/home/brundage-truck.jpg); }
  @media screen and (max-width: 45.0625em) {
    #home-heading {
      background-size: cover; } }
  @media screen and (min-width: 75em) {
    #home-heading {
      background-size: cover;
      height: 600px; }
      #home-heading #logo-section {
        margin-top: 100px; } }

.contact-content {
  position: relative; }

#contact-heading h1 {
  font-family: 'Work Sans', sans-serif;
  font-size: 4rem;
  color: #fff;
  font-weight: 800;
  margin-top: 80px;
  margin-bottom: 20px;
  text-align: center; }
  @media screen and (orientation: landscape) {
    #contact-heading h1.phone {
      font-size: 1.75em; } }
  #contact-heading h1.phone span.block {
    display: block; }
    @media screen and (orientation: landscape) {
      #contact-heading h1.phone span.block {
        display: inline-block; } }
  @media screen and (max-width: 45.0625em) {
    #contact-heading h1 {
      font-size: 2.5rem;
      margin-top: 10px;
      line-height: 3.25rem;
      margin-bottom: 60px; } }
  @media screen and (orientation: landscape) {
    #contact-heading h1 {
      margin-top: 20px; } }
  #contact-heading h1 span.caps {
    font-weight: 900;
    text-transform: uppercase; }

#contact-heading h2 {
  font-size: 1.75rem;
  line-height: 2.75rem;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #fff; }
  #contact-heading h2 span.sub-sub {
    font-family: 'Work Sans', sans-serif;
    font-weight: 900;
    font-size: 3rem; }
  @media screen and (max-width: 45.0625em) {
    #contact-heading h2 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-top: -20px;
      margin-bottom: 20px; } }

#contact-heading h3 {
  color: #fff;
  font-family: 'Eczar', serif;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 700; }

#contact-heading .phone {
  font-weight: 800;
  font-size: 2rem; }
  #contact-heading .phone a {
    color: white; }
  #contact-heading .phone i {
    top: 4px;
    position: relative;
    font-size: 2.25rem; }

#contact-heading section#logo-section {
  top: 30px;
  margin-bottom: 50px;
  position: relative; }

#contact-heading div#logo {
  text-align: center; }
  #contact-heading div#logo img {
    width: 550px; }
  @media screen and (orientation: landscape) {
    #contact-heading div#logo img.phone {
      width: 250px; } }

#sub-section {
  background: #214192;
  background-image: url(../../img/shared/brundage-sitework-metal.png); }

#contact-sub-heading {
  padding-bottom: 25px; }
  #contact-sub-heading section.contact-cta {
    font-size: 2rem; }
    @media screen and (min-width: 45.125em) {
      #contact-sub-heading section.contact-cta {
        margin-top: 20px; }
        #contact-sub-heading section.contact-cta a.secondary {
          margin-right: 50px; } }
    #contact-sub-heading section.contact-cta.phone {
      margin-top: 50px; }
      @media screen and (orientation: landscape) {
        #contact-sub-heading section.contact-cta.phone {
          margin-top: -40px; } }

#contact-heading {
  background: linear-gradient(rgba(0, 0, 0, 0.51), rgba(0, 0, 0, 0.51)), url(../../img/contact/brundage-sitework-contact.jpg);
  background-size: cover; }
  @media screen and (min-width: 90em) {
    #contact-heading {
      height: 500px; }
      #contact-heading h1 {
        margin-top: 100px !important; } }

#contact {
  margin-top: 30px;
  margin-bottom: 30px; }

@media screen and (min-width: 45.125em) {
  #call-today {
    margin-bottom: 100px; } }

#call-today, #hrs {
  color: #214192;
  font-weight: 700; }

#addy {
  color: #214192;
  font-weight: 700; }

.about-content {
  position: relative; }

#about-heading h1 {
  font-family: 'Work Sans', sans-serif;
  font-size: 4rem;
  color: #fff;
  font-weight: 800;
  margin-top: 80px;
  margin-bottom: 20px;
  text-align: center; }
  @media screen and (orientation: landscape) {
    #about-heading h1.phone {
      font-size: 1.75em; } }
  #about-heading h1.phone span.block {
    display: block; }
    @media screen and (orientation: landscape) {
      #about-heading h1.phone span.block {
        display: inline-block; } }
  @media screen and (max-width: 45.0625em) {
    #about-heading h1 {
      font-size: 2.5rem;
      margin-top: 10px;
      line-height: 3.25rem;
      margin-bottom: 40px; } }
  @media screen and (orientation: landscape) {
    #about-heading h1 {
      margin-top: 20px; } }
  #about-heading h1 span.caps {
    font-weight: 900;
    text-transform: uppercase; }

#about-heading h2 {
  font-size: 1.75rem;
  line-height: 2.75rem;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #fff; }
  #about-heading h2 span.sub-sub {
    font-family: 'Work Sans', sans-serif;
    font-weight: 900;
    font-size: 3rem; }
  @media screen and (min-width: 45.125em) and (max-width: 63.9375em) {
    #about-heading h2 {
      font-size: 3rem;
      margin-bottom: 50px; } }

#about-heading h3 {
  color: #fff;
  font-family: 'Eczar', serif;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 700; }

#about-heading .phone {
  font-weight: 800;
  font-size: 2rem; }
  #about-heading .phone a {
    color: white; }
  #about-heading .phone i {
    top: 4px;
    position: relative;
    font-size: 2.25rem; }

#about-heading section#logo-section {
  top: 30px;
  margin-bottom: 100px;
  position: relative; }
  #about-heading section#logo-section span.quote {
    font-family: 'Work Sans',sans-serif;
    position: absolute;
    margin-left: -50px;
    color: #fff;
    font-size: 5rem;
    margin-top: -40px; }
    @media screen and (max-width: 45.0625em) {
      #about-heading section#logo-section span.quote {
        margin-left: -30px; } }
  #about-heading section#logo-section p {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: left; }
  #about-heading section#logo-section p#quoted {
    position: relative; }
    @media screen and (min-width: 41.75em) {
      #about-heading section#logo-section p#quoted {
        margin-top: 100px; } }
    @media screen and (max-width: 45.0625em) {
      #about-heading section#logo-section p#quoted {
        margin-bottom: 20px; } }

#about-heading div#logo {
  text-align: center; }
  #about-heading div#logo img {
    width: 550px; }
  @media screen and (orientation: landscape) {
    #about-heading div#logo img.phone {
      width: 250px; } }

#sub-section {
  background: #214192;
  background-image: url(../../img/shared/brundage-sitework-metal.png); }

#about-sub-heading {
  padding-bottom: 25px; }
  #about-sub-heading section.about-cta {
    font-size: 2rem; }
    @media screen and (min-width: 45.125em) {
      #about-sub-heading section.about-cta {
        margin-top: 20px; }
        #about-sub-heading section.about-cta a.secondary {
          margin-right: 50px; } }
    #about-sub-heading section.about-cta.phone {
      margin-top: 50px; }
      @media screen and (orientation: landscape) {
        #about-sub-heading section.about-cta.phone {
          margin-top: -40px; } }

@media screen and (min-width: 26em) {
  #about-heading {
    height: 100vh; } }

@media screen and (max-width: 62.3125em) {
  #about-heading {
    background: linear-gradient(rgba(0, 0, 0, 0.51), rgba(0, 0, 0, 0.51)), url(../../img/about/corey-brundage-wellfeet-chamber.jpg);
    background-size: cover;
    background-position-x: right; } }

@media screen and (min-width: 62.3125em) {
  #about-heading {
    background: linear-gradient(rgba(0, 0, 0, 0.51), rgba(0, 0, 0, 0.51)), url(../../img/about/corey-brundage.jpg);
    background-size: cover;
    background-position-x: right; } }

@media screen and (min-width: 90em) {
  #about-heading {
    height: 750px; }
    #about-heading h1 {
      margin-top: 100px !important; } }

@media screen and (min-width: 2100px) {
  #about-heading {
    height: 880px;
    background-position-y: -50px; }
    #about-heading p {
      font-size: 2rem !important; } }

#letter {
  margin-top: 30px;
  text-align: left !important;
  border-bottom: 2px solid #214192; }
  #letter p {
    color: #214192;
    text-align: left !important; }
    #letter p span {
      margin-left: 50%; }
  #letter h2 {
    color: #214192;
    font-weight: 500; }

#about {
  margin-top: 30px;
  margin-bottom: 30px; }

#call-today {
  font-size: 2rem; }
  @media screen and (min-width: 45.125em) {
    #call-today {
      margin-bottom: 30px; } }
  @media screen and (max-width: 47em) {
    #call-today {
      font-size: 2rem; } }

#call-today, #hrs {
  color: #214192;
  font-weight: 700; }

#addy {
  color: #214192;
  font-weight: 700; }

.services-content {
  position: relative; }

.services-heading {
  position: relative; }
  .services-heading div#cta {
    margin-top: 30px;
    margin-bottom: 30px; }
    .services-heading div#cta .column-block {
      margin-top: 0rem;
      margin-bottom: 0rem; }
    .services-heading div#cta .ctas {
      color: #fff;
      text-align: center;
      position: relative; }
      .services-heading div#cta .ctas a {
        display: block;
        color: #fff; }
      .services-heading div#cta .ctas img.cta-icon {
        height: 70px; }
      .services-heading div#cta .ctas h3 {
        font-family: 'Work Sans', sans-serif;
        color: #214192;
        text-align: center;
        font-size: 1.75rem;
        font-weight: 400;
        line-height: 1.75rem;
        padding-top: 10px; }
        @media screen and (max-width: 45.0625em) {
          .services-heading div#cta .ctas h3 {
            font-size: 1.5rem;
            padding-bottom: 20px; } }
      .services-heading div#cta .ctas p {
        font-family: 'Lora', serif;
        font-size: 1.25rem;
        text-align: center;
        padding: 10px; }
        @media print, screen and (min-width: 45.125em) {
          .services-heading div#cta .ctas p {
            padding: 20px; } }
        @media screen and (max-width: 45.0625em) {
          .services-heading div#cta .ctas p {
            font-size: 1.5rem; } }
      .services-heading div#cta .ctas a.button {
        background-color: rgba(128, 127, 254, 0.65);
        border-color: #fff;
        font-family: 'Lora', serif !important;
        font-size: 1.125rem; }
        .services-heading div#cta .ctas a.button:hover {
          background-color: #807ffe; }
        @media print, screen and (min-width: 45.125em) {
          .services-heading div#cta .ctas a.button {
            position: absolute;
            bottom: 60px; } }
      .services-heading div#cta .ctas .container {
        width: 75%;
        margin: 0 auto; }
      .services-heading div#cta .ctas .circle {
        background: linear-gradient(rgba(255, 255, 255, 0.5), transparent), url(../../img/shared/round-metal-texture.png);
        border-radius: 50%;
        border: 1px solid #9C9C9C;
        position: relative;
        padding: 20px; }
      .services-heading div#cta .ctas .barrow {
        padding: 10px; }
        .services-heading div#cta .ctas .barrow img {
          left: -2px;
          position: relative; }
      .services-heading div#cta .ctas .faucet {
        padding: 15px; }
        .services-heading div#cta .ctas .faucet img {
          position: relative;
          top: 5px; }
      .services-heading div#cta .ctas .landscape {
        padding: 10px; }
        .services-heading div#cta .ctas .landscape img {
          position: relative;
          top: 0px;
          left: -2px; }
      .services-heading div#cta .ctas .excavator {
        padding: 10px; }
        .services-heading div#cta .ctas .excavator img {
          position: relative;
          left: -2px; }
      .services-heading div#cta .ctas .retail {
        padding: 6px; }
        .services-heading div#cta .ctas .retail img {
          position: relative;
          left: -2px; }
    .services-heading div#cta #water {
      border-left-width: 0px;
      border-right-width: 0px; }
      @media print, screen and (min-width: 45.125em) {
        .services-heading div#cta #water a.button {
          margin-left: -10%; } }
    .services-heading div#cta #septic {
      background-position: 0 -100px;
      border-left-width: 0px;
      border-right-width: 0px; }
      @media print, screen and (min-width: 45.125em) {
        .services-heading div#cta #septic a.button {
          margin-left: -14%; } }
    .services-heading div#cta #scapes {
      border-left-width: 0px;
      border-right-width: 0px; }
      @media print, screen and (min-width: 45.125em) {
        .services-heading div#cta #scapes a.button {
          margin-left: -13%; } }
    .services-heading div#cta #materials {
      border-left-width: 0px;
      border-right-width: 0px; }
      @media print, screen and (min-width: 45.125em) {
        .services-heading div#cta #materials a.button {
          margin-left: -13%; } }
  .services-heading h1 {
    font-family: 'Work Sans', sans-serif;
    font-size: 4rem;
    color: #fff;
    font-weight: 800;
    margin-top: 80px;
    margin-bottom: 20px;
    text-align: center; }
    @media screen and (min-width: 45.125em) and (max-width: 63.9375em) {
      .services-heading h1 {
        font-size: 3rem !important;
        margin-top: 0px !important; } }
    @media screen and (orientation: landscape) {
      .services-heading h1.phone {
        font-size: 1.75em; } }
    .services-heading h1.phone span.block {
      display: block; }
      @media screen and (orientation: landscape) {
        .services-heading h1.phone span.block {
          display: inline-block; } }
    @media screen and (max-width: 45.0625em) {
      .services-heading h1 {
        font-size: 2.5rem;
        margin-top: 10px;
        line-height: 3.25rem;
        margin-bottom: 20px; } }
    @media screen and (orientation: landscape) {
      .services-heading h1 {
        margin-top: 20px; } }
    @media screen and (min-width: 62.3125em) {
      .services-heading h1 {
        margin-top: 70px; } }
    .services-heading h1 span.caps {
      font-weight: 900;
      text-transform: uppercase; }
  .services-heading h2 {
    font-size: 1.75rem;
    line-height: 2.75rem;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    color: #fff; }
    @media screen and (max-width: 45.0625em) {
      .services-heading h2 {
        font-size: 1.25rem !important;
        line-height: 1.75rem; } }
    @media screen and (min-width: 45.125em) and (max-width: 63.9375em) {
      .services-heading h2 {
        font-size: 1.5rem !important;
        line-height: 2.5rem !important;
        margin-bottom: 10px !important; } }
    .services-heading h2 span.sub-sub {
      font-family: 'Work Sans', sans-serif;
      font-weight: 900;
      font-size: 3rem; }
  .services-heading h3 {
    color: #fff;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    font-size: 1.75rem;
    font-weight: 700; }
  .services-heading .phone {
    font-weight: 800;
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 30px; }
    .services-heading .phone a {
      color: white; }
    .services-heading .phone i {
      top: 4px;
      position: relative;
      font-size: 2.25rem; }
  .services-heading section#logo-section {
    top: 30px;
    margin-bottom: 100px;
    position: relative; }
    .services-heading section#logo-section span.quote {
      font-family: 'Work Sans',sans-serif;
      position: absolute;
      margin-left: -50px;
      color: #fff;
      font-size: 5rem;
      margin-top: -40px; }
      @media screen and (max-width: 45.0625em) {
        .services-heading section#logo-section span.quote {
          margin-left: -30px; } }
    .services-heading section#logo-section p {
      color: #fff;
      font-weight: 500;
      font-size: 1.5rem;
      text-align: center; }
    .services-heading section#logo-section p.phone {
      font-size: 2rem;
      font-weight: 800; }
    .services-heading section#logo-section p#quoted {
      position: relative; }
      @media screen and (min-width: 41.75em) {
        .services-heading section#logo-section p#quoted {
          margin-top: 100px; } }
      @media screen and (max-width: 45.0625em) {
        .services-heading section#logo-section p#quoted {
          margin-bottom: 20px; } }
  .services-heading div#logo {
    text-align: center; }
    .services-heading div#logo img {
      width: 550px; }
    @media screen and (orientation: landscape) {
      .services-heading div#logo img.phone {
        width: 250px; } }

#sub-section {
  background: rgba(33, 65, 146, 0.7), url(../../img/shared/brundage-sitework-metal.png); }

#services-sub-heading {
  padding-bottom: 25px; }
  #services-sub-heading section.services-cta {
    font-size: 2rem; }
    @media screen and (min-width: 45.125em) {
      #services-sub-heading section.services-cta {
        margin-top: 20px; }
        #services-sub-heading section.services-cta a.secondary {
          margin-right: 50px; } }
    #services-sub-heading section.services-cta.phone {
      margin-top: 50px; }
      @media screen and (orientation: landscape) {
        #services-sub-heading section.services-cta.phone {
          margin-top: -40px; } }

#services-water-heading {
  background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url(../../img/services/water-hookups/brundage-water-hookups.jpg);
  background-size: cover;
  overflow: visible; }
  @media screen and (max-width: 45.0625em) {
    #services-water-heading {
      background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url(../../img/services/water-hookups/brundage-water-hookups.jpg);
      background-size: cover;
      background-position-x: -415px;
      height: 100vh !important; } }
  #services-water-heading h1 {
    line-height: 2.5rem !important; }
    #services-water-heading h1 span {
      font-size: 1.5rem !important;
      font-weight: 500 !important; }

#services-landscape-heading {
  background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url(../../img/services/hardscape-landscape/brundage-hardscape-landscape3.jpg);
  background-size: cover;
  overflow: visible; }
  @media screen and (max-width: 45.0625em) {
    #services-landscape-heading {
      background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url(../../img/services/hardscape-landscape/brundage-hardscape-landscape3.jpg);
      background-size: cover;
      background-position-x: -415px;
      height: 100vh !important; } }
  @media screen and (min-width: 90em) {
    #services-landscape-heading {
      background-position-y: -250px; } }

#services-septic-heading {
  background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url(../../img/services/septic-excavation/brundage-septic-excavation.jpg);
  background-size: cover;
  overflow: visible; }
  @media screen and (max-width: 45.0625em) {
    #services-septic-heading {
      background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url(../../img/services/septic-excavation/brundage-septic-excavation.jpg);
      background-size: cover;
      background-position-x: -315px;
      height: 100vh !important; } }
  @media screen and (min-width: 62.3125em) {
    #services-septic-heading {
      background-size: cover; } }
  @media screen and (min-width: 90em) {
    #services-septic-heading {
      background-position-y: -375px; } }

#services-retail-heading {
  background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url(../../img/services/landscape-materials/brundage-landscape-materials.jpg);
  background-size: cover;
  overflow: visible; }
  @media screen and (max-width: 45.0625em) {
    #services-retail-heading {
      background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url(../../img/services/landscape-materials/brundage-landscape-materials.jpg);
      background-size: cover;
      background-position-x: -315px;
      height: 100vh !important; } }
  @media screen and (min-width: 62.3125em) {
    #services-retail-heading {
      background-size: cover; } }

.addy {
  text-decoration: underline !important;
  color: #999 !important; }

#letter {
  margin-top: 30px;
  text-align: left !important;
  border-bottom: 2px solid #214192; }
  #letter p {
    color: #214192;
    text-align: left !important; }
    #letter p span {
      margin-left: 50%; }
  #letter h2 {
    color: #214192;
    font-weight: 500; }

div.fb-post {
  margin-bottom: 30px; }

#services {
  margin-top: 30px;
  margin-bottom: 30px; }

#call-today, #hrs {
  color: #214192;
  font-weight: 700; }

#addy {
  color: #214192;
  font-weight: 700; }

#services-secondary {
  z-index: 10; }
  #services-secondary p.starter {
    margin-top: 40px;
    margin-bottom: 30px;
    font-family: 'Work Sans',sans-serif;
    color: #214192;
    font-size: 1.25rem;
    font-weight: 600; }

#services-tertiary {
  color: #214192 !important; }
  #services-tertiary h4 {
    font-family: 'Eczar', serif;
    text-align: center;
    font-weight: 700;
    color: #214192; }
  #services-tertiary p, #services-tertiary ul li {
    font-family: 'Work Sans',sans-serif;
    color: #214192;
    font-size: 1.125rem;
    font-weight: 500; }

table {
  background-color: #fff;
  border-collapse: collapse; }
  table caption {
    font-size: 1.5625rem;
    font-weight: 400;
    padding-top: 20px;
    margin-bottom: 10px;
    color: #214192; }
  table thead th, table thead td {
    text-align: center; }

@media screen and (max-width: 45.0625em) {
  #logo-section {
    top: 10px !important; }
  #home-sub-heading {
    padding-bottom: 10px; }
    #home-sub-heading h2 {
      margin-top: 20px; }
      #home-sub-heading h2 span.sub-sub {
        font-size: 2rem; }
  .menu-icon {
    width: 30px; }
    .menu-icon:after {
      height: 4px;
      box-shadow: 0 8px 0 #cacaca, 0 16px 0 #cacaca; }
  img.logo-svg-nav-lg {
    width: 50px; }
  .logo-wrapper {
    margin-left: 25px; }
  .top-bar ul li a {
    color: #777; }
  p {
    font-size: 1.125rem; }
  nav.sub-sticky-magellan li a.active span {
    box-shadow: none;
    color: #807ffe;
    text-decoration: none; }
    nav.sub-sticky-magellan li a.active span:hover {
      text-decoration: none;
      box-shadow: none; }
  nav.sub-sticky-magellan li a span:hover {
    text-decoration: none;
    box-shadow: none; }
  div.triangle {
    display: none; }
  #request-info h2 {
    font-size: 2rem;
    margin-top: -40px; }
  #wedding-sub-nav {
    position: relative;
    top: -8px;
    margin-left: -5px; }
    #wedding-sub-nav li > a {
      padding: 0px; }
  [type=checkbox] {
    margin-left: 20px; }
  #pp-content {
    margin-top: 10px; }
    #pp-content h2 {
      font-size: 1.5rem; }
  #slide-w1 {
    padding: 60px 0px; }
    #slide-w1 h3 {
      font-size: 2rem;
      line-height: 2.75rem; }
    #slide-w1 .phone-quote {
      font-size: 2.75rem; }
  .slide h3, #slide-w2 h3, #slide-w3 h3, #slide-w4 h3, #slide-w5 h3, #slide-w6 h3, #slide-w7 h3 {
    font-size: 2.5rem; }
  .slide p, #slide-w2 p, #slide-w3 p, #slide-w4 p, #slide-w5 p, #slide-w6 p, #slide-w7 p {
    font-size: 1.5rem; }
  .slide p.large, #slide-w2 p.large, #slide-w3 p.large, #slide-w4 p.large, #slide-w5 p.large, #slide-w6 p.large, #slide-w7 p.large {
    font-size: 1.5rem; }
  .reveal h4 {
    font-size: 1.5rem;
    margin-top: 20px;
    font-family: 'Playfair Display', serif; }
  .reveal ul li, .reveal p {
    font-size: 1.125rem;
    font-family: 'Lora', serif;
    color: #777; }
  form input[type=submit] {
    font-size: 1.5rem; }
  footer a p, footer p {
    font-size: 1rem; }
  footer .column {
    padding-right: .5rem;
    padding-left: .5rem; }
  #menu-sections h3 {
    font-size: 2rem; } }

@media screen and (orientation: landscape) {
  #landscape-off, .landscape-off {
    display: none !important; } }
