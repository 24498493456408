
// phone portrait
@include breakpoint(small only) {
	//main logo and heading area
	#logo-section{
		top:10px !important;
	}

	#home-sub-heading{
		padding-bottom: 10px;
		h2{
			margin-top:20px;
			span.sub-sub{
				font-size: 2rem;
			}
		}
	}


	// navigation

	.menu-icon{
		width:30px;
		&:after{
			height:4px;
			box-shadow: 0 8px 0 #cacaca, 0 16px 0 #cacaca;
		}
	}


	img.logo-svg-nav-lg{
	width: 50px;
	}

	.logo-wrapper{
		margin-left:25px;
	}

	.top-bar ul li a{
		 color: #777;
	}

	p{
		font-size: 1.125rem;
	}

	nav.sub-sticky-magellan li a.active span{
		box-shadow: none;
		color: #807ffe;
		text-decoration: none;
		&:hover{
			text-decoration: none;
			box-shadow: none;
		}

	}
	nav.sub-sticky-magellan li a span{
		&:hover{
			text-decoration:none;
			box-shadow: none;
		}
	}
	div.triangle{
		display: none;
	}

	#request-info{
		h2{
			font-size:2rem;
			margin-top: -40px;
		}
	}

	#wedding-sub-nav{
		position: relative;
		top:-8px;
		margin-left: -5px;

		li>a{
			padding: 0px;
		}
	}
	
	[type=checkbox]{
		margin-left: 20px;
	}

	#pp-content{
		margin-top: 10px;

		h2{
			font-size:1.5rem;
		}
	}

	#slide-w1{
		padding: 60px 0px; 

		h3{
			font-size: 2rem;
			line-height: 2.75rem;
		}
		.phone-quote{
			font-size:2.75rem;
		}
	}

	.slide, #slide-w2, #slide-w3, #slide-w4, #slide-w5, #slide-w6, #slide-w7{
		h3{
			font-size: 2.5rem;
		}
		p{font-size:1.5rem}
		p.large{font-size:1.5rem}
	}

	.reveal{

		h4{
			font-size: 1.5rem;
			margin-top: 20px;
			font-family: 'Playfair Display', serif;
		}
		ul li, p{
			font-size: 1.125rem;
			font-family: 'Lora', serif;
			color: #777;

		}
	}
	form input[type=submit]{
		font-size: 1.5rem;
	}

	footer{
		a p, p {font-size: 1rem;}
		.column{
			padding-right:.5rem;
			padding-left:.5rem
		}
	}

	// nav#dining-sub-nav{
	// 	padding:0px;
	// 	ul {
	// 		text-align: center;

	// 		li#cocktails-col{
	// 			position: relative;
	// 			left:8px;
	// 		}
	// 		li#prix-fixe-col{
	// 			position: relative;
	// 			width:40px;
	// 			left: -5px;
	// 		}
	// 		li#desserts-col{
	// 			position: relative;
	// 			left:3px;
	// 		}

			

			
	// 		li {
	// 			text-align: center;
	// 			a {
	// 				text-align: center;
	// 				padding-left: 0rem;
	// 				padding-right: 0rem;
	// 				span{
	// 					text-align: center;
	// 					font-size: 1rem;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	#menu-sections{
		h3{
			font-size: 2rem;

		}
	}
}

// Hide on landscape -- used only with detect mobile adding classes, except ID??
@include breakpoint(landscape) {
	#landscape-off, .landscape-off{
		display: none !important;
	}
}

