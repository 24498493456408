//variables
	
.services-content{
	position: relative;
    // top: -92px;
    // z-index: -1;
}

// #miaNadeau{
// 	h1, p{
// 		color:red !important;
// 	}

// 	p.miaGrace{
// 		color:pink !important;
// 		text-decoration: underline;
// 		text-transform: uppercase;
// 		font-size: 30px;

// 	}

// 	p.love{
// 		color: lightblue !important;
// 		font-size: 300px;
// 	}
// }

.services-heading{
	position: relative;
	// z-index: -1;

  	div#cta{
	// border-bottom: 2px solid #fff;
	margin-top: 30px;
	margin-bottom: 30px;
	
	.column-block{
		margin-top: 0rem;
		margin-bottom: 0rem;
		
	}

	.ctas{
		

		
		// border-style: solid;
		// border-color: #fff;
		// border-top-width:2px;
		// border-bottom-width: 0px;
		color: #fff;
		text-align: center;
		
		position: relative;

		a{
			display: block;
			color: #fff;	
		}

		img{
			
		}

		img.cta-icon{
			height: 70px;
		}

		h3{
			font-family: 'Work Sans', sans-serif;
			color: $brundage-blue;
			text-align: center;
			font-size: 1.75rem;
			font-weight: 400;
			line-height: 1.75rem;
			padding-top: 10px;
			@include breakpoint(small only) {
				font-size: 1.5rem;
				padding-bottom: 20px;
			}
		}
		
		p{
			font-family: 'Lora', serif;
			font-size: 1.25rem;
			text-align: center;
			padding: 10px;
			@include breakpoint(medium) {
				padding: 20px;
			}
			@include breakpoint(small only) {
				font-size: 1.5rem;
			}
			
		}

		a.button{
			background-color: rgba(128,127,254,.65);
			
			border-color: #fff;
			font-family: 'Lora', serif !important;
			font-size: 1.125rem;
    		
    		&:hover{
				background-color: rgba(128,127,254,1);
			}
			@include breakpoint(medium) {
				position: absolute;
		    	bottom: 60px;
		    	
		    	
		    }
		}

		.container{
			width: 75%;
			margin: 0 auto;
		}
		.circle{
			// background-color: rgba(#aaa, .42);
			// background: rgba(216,216,216,.7);

			background: linear-gradient( rgba(255,255,255, $bg-tint-0), rgba(0, 0, 0, $bg-tint-00) ),url(../../img/shared/round-metal-texture.png);
		    border-radius: 50%;
		    border: 1px solid #9C9C9C;
		    position: relative;
		    padding: 20px;
		}

		.barrow{
			padding:10px;
			img{
				left: -2px;
				position: relative;
			}
		}

		.faucet{
			padding: 15px;
			img{
				position: relative;
				top:5px;
			}
		}

		.landscape{
			padding: 10px;
			img{
				position: relative;
				top:0px;
				left: -2px;
			}
		}

		.excavator{
			padding: 10px;
			img{
				position: relative;
				left:-2px;
			}
		}

		.retail{
			padding: 6px;
			img{
				position: relative;
				left:-2px;
			}
		}

	}


	#water{
		
		border-left-width: 0px;
		border-right-width: 0px;
		a.button{
			@include breakpoint(medium) {
			    	margin-left: -10%;
			    	
		    }
		}
		
	}

	#septic{
		
			background-position: 0 -100px;
		
		@include breakpoint(large) {
			
		}

		a.button{
			@include breakpoint(medium) {
		    	margin-left: -14%;
		    	
	    	}
		}

		

		
		@include breakpoint(medium) {
			
		}
		border-left-width: 0px;
		border-right-width: 0px;
	}

	#scapes{
		
		border-left-width: 0px;
		border-right-width: 0px;
		@include breakpoint(small only) {
			// height: 470px;
		}


		a.button{
			@include breakpoint(medium) {
			    	margin-left: -13%;
			    	
		    }
		}

	}


	#materials{
		
		border-left-width: 0px;
		border-right-width: 0px;
		@include breakpoint(small only) {
			// height: 470px;
		}


		a.button{
			@include breakpoint(medium) {
			    	margin-left: -13%;
			    	
		    }
		}

	}
}
    
    h1{
		font-family: 'Work Sans', sans-serif;
		@include breakpoint(medium only) { 
			font-size: 3rem !important;
			margin-top: 0px !important;
		}
		font-size: 4rem;
		color: #fff;
		font-weight: 800;
		// text-shadow: 1px 1px 2px #444;
		margin-top:  80px;
		margin-bottom: 20px;

		&.phone{
			@include breakpoint(landscape) {
				font-size: 1.75em;
			}
			span.block{
				display: block;
				@include breakpoint(landscape) {
					display: inline-block;
				}
			}
		}

		@include breakpoint(small only) { 
			font-size: 2.5rem;
			margin-top: 10px;
			line-height: 3.25rem;
			margin-bottom: 20px;

		}
		@include breakpoint(landscape) {
				margin-top: 20px;

		}

		@media screen and (min-width: 62.3125em){	
			margin-top: 70px;
		}


		
		text-align: center;
		span.caps{
			font-weight: 900;
			text-transform: uppercase;
		}
		span.block{
			@include breakpoint(medium only) {  
				
			}
			@include breakpoint(landscape) {
				
			}
		}
		
	}

	h2{
		

		@include breakpoint(small only) {
		font-size: 1.25rem !important;
		line-height: 1.75rem;
		}

		@include breakpoint(medium only) {
		font-size: 1.5rem !important;
		line-height: 2.5rem !important;
		margin-bottom: 10px !important;
		}

		font-size: 1.75rem;
		line-height: 2.75rem;
		// margin-top: 50px;
		
		
		font-family: 'Work Sans', sans-serif;
		font-weight: 600;
		
		
		text-align: center;
		
		color: #fff;

		span.sub-sub{
			font-family: 'Work Sans', sans-serif;
			font-weight: 900;
			font-size:3rem;
		}

		
		
		// @include breakpoint(large) {
		// 	font-size: 2.75rem;		
		// 	margin-top: 30px;
		// }
	}

	h3{
		
		color: #fff;
		font-family: 'Work Sans', sans-serif;
		text-transform: uppercase;
		font-size: 1.75rem;
		font-weight: 700;
	}
 
	.phone{
		font-weight: 800;
		font-size: 2rem;
		a{color: white;}
		i{
			top: 4px;
		    position: relative;
		    font-size: 2.25rem;
		}
		margin-top: 20px;
		margin-bottom: 30px;
	}

	section#logo-section{
		top:30px;
		margin-bottom: 100px;
		position: relative;
		span.quote{
		    font-family: 'Work Sans',sans-serif;
		    position: absolute;
		    margin-left: -50px;
		    color: #fff;
		    font-size: 5rem;
		    margin-top: -40px;
		    @include breakpoint(small only) {  
			margin-left: -30px;	
			}

		}
		p{
			color: #fff;
			font-weight: 500;
			font-size: 1.5rem;
			text-align: center;
		}
		p.phone{
			font-size: 2rem;
			font-weight: 800;
		}
		p#quoted{
			position: relative;
			@media screen and (min-width: 41.75em){	
				margin-top: 100px;
			}
			@include breakpoint(small only) {  
			margin-bottom:20px;
			}

		}
	}

	div#logo{
		text-align: center;
		img{
			width:550px;
			

		}
		img.phone{
			@include breakpoint(landscape) {
				width:250px;
				
			}
		}
				
	}

	
}

#sub-section{
	background: rgba(33,65,146,.7), url(../../img/shared/brundage-sitework-metal.png);
}

#services-sub-heading{
			
	padding-bottom: 25px;
	

	section.services-cta{
		font-size: 2rem;

		@include breakpoint(medium up) { 
			margin-top:20px;
			a.secondary{
			margin-right: 50px;
			}
		}

		&.phone{
			margin-top:50px;

			@include breakpoint(landscape) {
				margin-top:-40px;	
			}
		}	
	}

}



#services-water-heading{
	@include breakpoint(small only) {
		background: linear-gradient( rgba(0, 0, 0, $bg-tint-62), rgba(0, 0, 0, $bg-tint-62) ),url(../../img/services/water-hookups/brundage-water-hookups.jpg);
		background-size: cover;
		background-position-x: -415px;
		// 	background-position-x: right;
		height: 100vh !important;
	}
	
	// height: 90vh !important;
	background: linear-gradient( rgba(0, 0, 0, $bg-tint-62), rgba(0, 0, 0, $bg-tint-62) ),url(../../img/services/water-hookups/brundage-water-hookups.jpg);
	background-size: cover;
	overflow: visible;

	@media screen and (min-width: 62.3125em){
		// height: 80vh !important;
	}	

	h1{
		line-height: 2.5rem !important;;
		span{
			font-size: 1.5rem !important;
			font-weight: 500 !important;


		}
	}

	
}


#services-landscape-heading{
	@include breakpoint(small only) {
		background: linear-gradient( rgba(0, 0, 0, $bg-tint-62), rgba(0, 0, 0, $bg-tint-62) ),url(../../img/services/hardscape-landscape/brundage-hardscape-landscape3.jpg);
		background-size: cover;
		background-position-x: -415px;
		// 	background-position-x: right;
		height: 100vh !important;
	}
	
	// height: 90vh !important;
	background: linear-gradient( rgba(0, 0, 0, $bg-tint-62), rgba(0, 0, 0, $bg-tint-62) ),url(../../img/services/hardscape-landscape/brundage-hardscape-landscape3.jpg);
	background-size: cover;
	overflow: visible;

	@media screen and (min-width: 62.3125em){
		// height: 80vh !important;
	}	

	@include breakpoint(xxlarge up) { 
		background-position-y: -250px;
	}
	
}	


#services-septic-heading{
	@include breakpoint(small only) {
		background: linear-gradient( rgba(0, 0, 0, $bg-tint-62), rgba(0, 0, 0, $bg-tint-62) ),url(../../img/services/septic-excavation/brundage-septic-excavation.jpg);
		background-size: cover;
		background-position-x: -315px;
		// 	background-position-x: right;
		height: 100vh !important;
	}
	
	// height: 100vh !important;
	background: linear-gradient( rgba(0, 0, 0, $bg-tint-62), rgba(0, 0, 0, $bg-tint-62) ),url(../../img/services/septic-excavation/brundage-septic-excavation.jpg);
	background-size: cover;
	overflow: visible;

	@media screen and (min-width: 62.3125em){
		// height: 80vh !important;
		// background-position-y: -200px;
		background-size: cover;
	}	

	@include breakpoint(xxlarge up) { 
		background-position-y: -375px;
	}
	
}

#services-retail-heading{
	@include breakpoint(small only) {
		background: linear-gradient( rgba(0, 0, 0, $bg-tint-62), rgba(0, 0, 0, $bg-tint-62) ),url(../../img/services/landscape-materials/brundage-landscape-materials.jpg);
		background-size: cover;
		background-position-x: -315px;
		// 	background-position-x: right;
		height: 100vh !important;
	}
	
	// height: 100vh !important;
	background: linear-gradient( rgba(0, 0, 0, $bg-tint-62), rgba(0, 0, 0, $bg-tint-62) ),url(../../img/services/landscape-materials/brundage-landscape-materials.jpg);
	background-size: cover;
	overflow: visible;

	@media screen and (min-width: 62.3125em){
		// height: 80vh !important;
		// background-position-y: -200px;
		background-size: cover;
	}	
	
}	

.addy{
	text-decoration: underline !important;
	color: #999 !important;
}

#letter{
	margin-top: 30px;
	text-align: left !important;
	border-bottom: 2px solid $brundage-blue;
	p{
		color: $brundage-blue;
		text-align: left !important;
		span{
			margin-left:50%;
		}
	}

	h2{
		color: $brundage-blue;
		font-weight: 500;
	}

}

// facebook testimonials
div.fb-post{
	margin-bottom: 30px;
}
iframe#erica{
	
	div._5pcb{
		// width: 10px !important;
		
	}
	@include breakpoint(small only){
		// width: 100% !important;
	}
}

#services{
	margin-top: 30px;
	margin-bottom: 30px;
}

#call-today{
	// @include breakpoint(medium up) { 
	// 	margin-bottom: 30px;
	// }
	// @media screen and (max-width: 47em){	
	// font-size: 2rem;
	// }
	// font-size: 3rem;
}

#call-today, #hrs{
	color: $brundage-blue;
	font-weight: 700;
}

#addy{
	color: $brundage-blue;
	font-weight: 700;
}

#services-secondary{
	z-index: 10;
	

	p.starter{
		margin-top: 40px;
		margin-bottom: 30px;
		font-family: 'Work Sans',sans-serif;
		color: $brundage-blue;
		font-size:1.25rem;
		font-weight: 600;
	}
}

#services-tertiary{
	color: $brundage-blue !important;
	h4{
		font-family: 'Eczar', serif;
		text-align: center;
		font-weight: 700;
		color: $brundage-blue
	}
	p, ul li{
		font-family: 'Work Sans',sans-serif;
		color: $brundage-blue;
		font-size: 1.125rem;
		font-weight: 500;
	}
}



table{
	background-color: #fff;
	border-collapse: collapse;

	&.card-section{
		// margin-bottom:10px !important;
	}

	caption{
			font-size:1.5625rem;
			font-weight: 400;
			padding-top: 20px;
			margin-bottom: 10px;
			color: $brundage-blue;
		}
	thead th, thead td{text-align: center;}
	// border:1px solid $brundage-blue;
}