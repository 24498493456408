


// @import "../../bower_components/foundation-sites/scss/_global.scss";
// @import "../../bower_components/foundation-sites/scss/foundation.scss";
@import "../../bower_components/foundation-sites/scss/settings/_settings.scss";
// @import "../../bower_components/foundation-sites/scss/foundation.scss";
@import "../../bower_components/foundation-sites/dist/css/foundation.min.css";

// @import "../vendor/foundation-sites/scss/_global.scss";
// @import "../vendor/foundation-sites/dist/css/foundation.min.css";
// @import "../vendor/foundation-sites/scss/settings/_settings.scss";

// @import "foundation-sites/scss/_global";
// @import "foundation-sites/scss/settings/_settings";
// @import "foundation-sites/scss/foundation";




// Variables
@import "variables/custom_variables";


//fonts

@import "webfonts";



//pages etc
// @import "test";
// @import "foundation";

@import "shared";
@import "nav";
@import "footer";
@import "home";
@import "contact";
@import "about";
@import "services";
// @import "photoswipe";
// @import "default-skin/default-skin.scss";
@import "mobileOverrides";

// @import "app";
