//variables
	
.contact-content{
	position: relative;
    // top: -92px;
    // z-index: -1;
}

#contact-heading{
  
    @include breakpoint(small only) { 
    

    }
    h1{
		font-family: 'Work Sans', sans-serif;
		
		font-size: 4rem;
		color: #fff;
		font-weight: 800;
		// text-shadow: 1px 1px 2px #444;
		margin-top:  80px;
		margin-bottom: 20px;

		&.phone{
			@include breakpoint(landscape) {
				font-size: 1.75em;
			}
			span.block{
				display: block;
				@include breakpoint(landscape) {
					display: inline-block;
				}
			}
		}

		@include breakpoint(small only) { 
			font-size: 2.5rem;
			margin-top: 10px;
			line-height: 3.25rem;
			margin-bottom: 60px;

		}
		@include breakpoint(landscape) {
				margin-top: 20px;

			}


		
		text-align: center;
		span.caps{
			font-weight: 900;
			text-transform: uppercase;
		}
		span.block{
			@include breakpoint(medium only) {  
				
			}
			@include breakpoint(landscape) {
				
			}
		}
		
	}

	h2{
		

		font-size: 1.75rem;
		line-height: 2.75rem;
		// margin-top: 50px;
		
		
		font-family: 'Work Sans', sans-serif;
		font-weight: 600;
		
		
		text-align: center;
		
		color: #fff;

		span.sub-sub{
			font-family: 'Work Sans', sans-serif;
			font-weight: 900;
			font-size:3rem;
		}
		@include breakpoint(small only) {
			font-size: 1.25rem;
			line-height: 1.75rem;
			margin-top: -20px;
			margin-bottom: 20px;
		}
		@include breakpoint(medium only) {
		
			// font-size: 3rem;	
			// margin-bottom: 50px;
			// margin-top: 30px;
		}
		
		// @include breakpoint(large) {
		// 	font-size: 2.75rem;		
		// 	margin-top: 30px;
		// }
	}

	h3{
		color: #fff;
		font-family: 'Eczar', serif;
		text-transform: uppercase;
		font-size: 1.25rem;
		font-weight: 700;
	}
 
	.phone{
		font-weight: 800;
		font-size: 2rem;
		a{color: white;}
		i{
			top: 4px;
		    position: relative;
		    font-size: 2.25rem;
		}
	}

	section#logo-section{
		top:30px;
		margin-bottom: 50px;
		position: relative;
	}

	div#logo{
		text-align: center;
		img{
			width:550px;
			

		}
		img.phone{
			@include breakpoint(landscape) {
				width:250px;
				
			}
		}
				
	}

	
}

#sub-section{
	background: rgba(33,65,146,1);
		background-image: url(../../img/shared/brundage-sitework-metal.png);
}

#contact-sub-heading{
			
	padding-bottom: 25px;
		
	


	
	

	

	section.contact-cta{
		font-size: 2rem;

		@include breakpoint(medium up) { 
			margin-top:20px;
			a.secondary{
			margin-right: 50px;
			}
		}

		&.phone{
			margin-top:50px;

			@include breakpoint(landscape) {
				margin-top:-40px;	
			}
		}	
	}

}



#contact-heading{
	background: linear-gradient( rgba(0, 0, 0, $bg-tint-25), rgba(0, 0, 0, $bg-tint-25) ),url(../../img/contact/brundage-sitework-contact.jpg);
	background-size: cover;
	@include breakpoint(xxlarge up) { 
		height: 500px;
		h1{
			margin-top: 100px !important;
		}
	}
}	

#contact{
	margin-top: 30px;
	margin-bottom: 30px;
}

#call-today{
	@include breakpoint(medium up) { 
		margin-bottom: 100px;
	}
}

#call-today, #hrs{
	color: $brundage-blue;
	font-weight: 700;
}

#addy{
	color: $brundage-blue;
	font-weight: 700;
}

