//variables
	
.home-content{
	position: relative;
    // top: -92px;
    // z-index: -1;
}

#home-heading{
	

	
    

    

	


	.phone{
		font-weight: 800;
		font-size: 2rem;
		a{color: white;}
		i{
			top: 4px;
		    position: relative;
		    font-size: 2.25rem;
		}
	}

	section#logo-section{
		// top:30px;
		margin-bottom: 50px;
		position: relative;
	}

	div#logo{
		text-align: center;
		margin-top: 10px;
		img{
			width:550px;
			@include breakpoint(xlarge up) { 
			width:750px;
			}

		}
		img.phone{
			@include breakpoint(landscape) {
				width:250px;
				
			}
		}
		

				
	}

	
	
}

#sub-section{
	background: rgba(33,65,146,1);
		background-image: url(../../img/shared/brundage-sitework-metal.png);
}

#home-sub-heading{
		
		
		
		padding-bottom: 25px;
		
	}

	// h1{
	// 	font-family: 'Cinzel', serif;
	// 	text-transform: uppercase;
	// 	font-size: 5rem;
	// 	color: #dfdfdf;
		
	// 	text-shadow: 1px 1px 2px #444;
	// 	margin-top:  80px;
	// 	margin-bottom: 20px;

	// 	&.phone{
	// 		@include breakpoint(landscape) {
	// 			font-size: 1.75em;
	// 		}
	// 		span.block{
	// 			display: block;
	// 			@include breakpoint(landscape) {
	// 				display: inline-block;
	// 			}
	// 		}
	// 	}

	// 	@include breakpoint(small only) { 
	// 		font-size: 2.5rem;
	// 		margin-top: 10px;
	// 		line-height: 3.25rem;
	// 		margin-bottom: 60px;

	// 	}
	// 	@include breakpoint(landscape) {
	// 			margin-top: 20px;

	// 		}


		
	// 	text-align: center;
	// 	span.caps{
	// 		font-weight: 900;
	// 		text-transform: uppercase;
	// 	}
	// 	span.block{
	// 		@include breakpoint(medium only) {  
				
	// 		}
	// 		@include breakpoint(landscape) {
				
	// 		}
	// 	}
		
	// }
	
	h2#home-sub{
		

		font-size: 1.75rem;
		line-height: 2.75rem;
		margin-top: 50px;
		
		
		font-family: 'Eczar', serif;
		font-weight: 600;
		text-transform: uppercase;
		
		text-align: center;
		
		color: #fff;

		span.sub-sub{
			font-family: 'Work Sans', sans-serif;
			font-weight: 900;
			font-size:3rem;
		}

		@include breakpoint(medium only) {
		
			font-size: 3rem;	
			margin-bottom: 50px;
			margin-top: 30px;
		}
		
		@include breakpoint(large) {
			font-size: 2.75rem;		
			margin-top: 30px;
		}
	}

	h3{
		color: #fff;
		font-family: 'Eczar', serif;
		text-transform: uppercase;
		font-size: 1.25rem;
		font-weight: 700;
	}

	

	section.home-cta{
		font-size: 2rem;

		@include breakpoint(medium up) { 
			margin-top:20px;
			a.secondary{
			margin-right: 50px;
			}
		}

		&.phone{
			margin-top:50px;

			@include breakpoint(landscape) {
				margin-top:-40px;	
			}
		}	
	}

div#cta{
	// border-bottom: 2px solid #fff;
	margin-top: 30px;
	margin-bottom: 30px;
	@include breakpoint(xlarge up) { 
		margin-top: 60px;
	}
	
	.column-block{
		margin-top: 0rem;
		margin-bottom: 0rem;
		
	}

	.ctas{
		

		
		// border-style: solid;
		// border-color: #fff;
		// border-top-width:2px;
		// border-bottom-width: 0px;
		color: #fff;
		text-align: center;
		
		position: relative;

		a{
			display: block;
			color: #fff;	
		}

		img{
			
		}

		img.cta-icon{
			height: 70px;
		}

		h3{
			font-family: 'Work Sans', sans-serif;
			color: $brundage-blue;
			text-align: center;
			font-size: 1.25rem;
			font-weight: 400;
			line-height: 1.75rem;
			padding-top: 10px;
			@include breakpoint(small only) {
				font-size: 1.5rem;
				padding-bottom: 20px;
			}
		}
		
		p{
			font-family: 'Lora', serif;
			font-size: 1.25rem;
			text-align: center;
			padding: 10px;
			@include breakpoint(medium) {
				padding: 20px;
			}
			@include breakpoint(small only) {
				font-size: 1.5rem;
			}
			
		}

		a.button{
			background-color: rgba(128,127,254,.65);
			
			border-color: #fff;
			font-family: 'Lora', serif !important;
			font-size: 1.125rem;
    		
    		&:hover{
				background-color: rgba(128,127,254,1);
			}
			@include breakpoint(medium) {
				position: absolute;
		    	bottom: 60px;
		    	
		    	
		    }
		}

		.container{
			width: 75%;
			margin: 0 auto;
			@include breakpoint(xlarge up) { 
			width: 150px;
			}
		}
		.circle{
			background-color: rgba(#aaa, .42);
		    border-radius: 50%;
		    border: 1px solid #9C9C9C;
		    position: relative;
		    padding: 20px;
		}
		.barrow{
			padding:10px;
			img{
				left: -2px;
				position: relative;
			}
		}
		.faucet{
			padding: 15px;
			img{
				position: relative;
				top:5px;
			}

			
		}

	}


	#water{
		
		border-left-width: 0px;
		border-right-width: 0px;
		a.button{
			@include breakpoint(medium) {
			    	margin-left: -10%;
			    	
		    }
		}
		
	}

	#septic{
		
			background-position: 0 -100px;
		
		@include breakpoint(large) {
			
		}

		a.button{
			@include breakpoint(medium) {
		    	margin-left: -14%;
		    	
	    	}
		}

		

		
		@include breakpoint(medium) {
			
		}
		border-left-width: 0px;
		border-right-width: 0px;
	}

	#scapes{
		
		border-left-width: 0px;
		border-right-width: 0px;
		@include breakpoint(small only) {
			// height: 470px;
		}


		a.button{
			@include breakpoint(medium) {
			    	margin-left: -13%;
			    	
		    }
		}

	}


	#materials{
		
		border-left-width: 0px;
		border-right-width: 0px;
		@include breakpoint(small only) {
			// height: 470px;
		}


		a.button{
			@include breakpoint(medium) {
			    	margin-left: -13%;
			    	
		    }
		}

	}
}


.arrow {
    position: relative;
    margin: 0 auto;
    width: 45px;
    height: 24px;
   
    background-size: contain;

}

.arrow-row{
	
	position: absolute;
    bottom: 20px;
    margin-left: 44%;
    @include breakpoint(medium) {
    	margin-left: 48%;
    	bottom: 40px;
    }
}

//from events


#home-heading{
	background: linear-gradient( rgba(0, 0, 0, $bg-tint-2), rgba(0, 0, 0, $bg-tint-2) ),url(../../img/home/brundage-truck.jpg);
	
    

	// img.title-icon{
 //    	width: 100px;
 //    	margin-top: -100px;
 //    	margin-right:-30px;
 //    	@include breakpoint(small only) { 
    		
 //    		width: 70px;
 //    		margin-top: 50px;
 //    		margin-bottom: 30px;
		
 //    	}
 //    }
 //    height: 50vh;
    
	@include breakpoint(small only) { 
		background-size: cover;
		// background-position-y: 100px;
		// background-position-x: -115px;
			// background-position-x: left;
		// height: 100vh !important;
	}	

	@include breakpoint(xlarge up) { 
		background-size: cover;
		height: 600px;
		#logo-section{
			margin-top: 100px;
		}

		
		// h1{
		// 	margin-top: 100px !important;
		// }
	}
 //    	background-size: 120%;
 //    	height: 40vh;
    	
 //    	h1{
 //    		margin-top: -30px;
 //    	}
 //    	img.title-icon{
	// 		margin-top: 30px
	// 	}
 //    }

 //    @include breakpoint(medium only) { 
	// 	background-position: 0px -150px;
 //    	background-size: 100%;
 //    	height: 40vh;
    	
 //    	background-color: #fff;
 //    	h1{
 //    		margin-top: -30px;
 //    	}
 //    	img.title-icon{
	// 		margin-top: 30px
	// 	}
 //    }

 //    //for larger than phone
 //    @include breakpoint(large only) { 
	
	// 	background-position: 0px -250px !important;
 //    	background-size: 100%;
 //    	height: 50vh;

		
	// 	.row section#section-1{
	// 		margin-top: 140px;
	// 	}
	// }
	
	// @include breakpoint(xlarge only) { 
	// 	background-position: 0px -360px;
 //    	background-size: cover;
 //    	height: 50vh;
 //    	img.title-icon{
 //    		margin-top: 50px;
 //    	}

 //    	h1{
 //    		margin-top: 10px;
 //    	}
	// }

	// margin-top: 0px;
   
 //    @include breakpoint(small only) { 
   

 //    }
    
    

 //    &.tablet{
 //    	background-position: 0px -250px !important;
   
 //    	h1{
 //    		margin-top: -20px;
 //    	}
 //    }

	// &.phone{
 //    	background-position: -40px -350px !important;
 //    	img.title-icon{
	// 		margin-right: -20px;
	// 	}
	// 	h1{
 //    		margin-top: -30px;
 //    	}
 //    }
	


	// .phone{

	// }

	
	// div.logo{
	// 	text-align: center;
	// 	img{
	// 		width:650px;
	// 		@include breakpoint(small only) {
	// 			margin-top: 20px; 
	// 			width:350px;
	// 		}
	// 		@include breakpoint(landscape) {
	// 			width:375px;
	// 			margin-bottom: 0px;
	// 			}
			

	// 	}
	// 	img.phone{
	// 		@include breakpoint(landscape) {
	// 			width:250px;
				
	// 		}
	// 	}
	
				
	// }
	// h1{
	// 	font-family: 'Crimson Text', serif;
	// 	text-transform: uppercase;
	// 	font-size: 5rem;
	// 	color: #dfdfdf;
		
	// 	text-shadow: 1px 1px 2px #444;
	// 	margin-top:  -30px;
	// 	margin-bottom: 20px;

	// 	@include breakpoint(small only) { 
	// 		font-size: 2.5rem;
	// 		margin-top: 40px;
	// 		line-height: 3.25rem;
			

	// 	}

	// 	&.phone{
	// 		@include breakpoint(landscape) {
	// 			font-size: 1.75em;
	// 		}

	// 		span.block{
	// 			display: block;
	// 			@include breakpoint(landscape) {
	// 				display: inline-block;
	// 			}
	// 		}
	// 	}

		
	// 	@include breakpoint(landscape) {
	// 			margin-top: 20px;

	// 		}


		
	// 	text-align: center;
	// 	span.caps{
	// 		font-weight: 900;
	// 		text-transform: uppercase;
	// 	}
	// 	span.block{
	// 		@include breakpoint(medium only) {  
				
	// 		}
	// 		@include breakpoint(landscape) {
				
	// 		}
	// 	}
		
	// }
	
	// h2{
		

	// 	font-size: 1.75rem;
	// 	line-height: 2.75rem;
	// 	margin-top: 50px;
		
		
	// 	font-family: 'Playfair Display', serif;
		
	// 	text-align: center;
	// 	font-style: italic;
	// 	color: #fff;
	// 	@include breakpoint(medium only) {
		
	// 		font-size: 3rem;	
	// 		margin-bottom: 50px;
	// 		margin-top: 30px;
	// 	}
		
	// 	@include breakpoint(large) {
	// 		font-size: 2.75rem;		
	// 		margin-top: 30px;
	// 	}
	// }

	

	// section.home-cta{
	// 	font-size: 2rem;

	// 	@include breakpoint(medium up) { 
	// 		margin-top:20px;
	// 		a.secondary{
	// 		margin-right: 50px;
	// 		}
	// 	}

	// 	&.phone{
	// 		margin-top:50px;

	// 		@include breakpoint(landscape) {
	// 			margin-top:-40px;	
	// 		}
	// 	}	
	// }
	
}
