//variables
	
.about-content{
	position: relative;
    // top: -92px;
    // z-index: -1;
}

#about-heading{
  
    
    h1{
		font-family: 'Work Sans', sans-serif;
		
		font-size: 4rem;
		color: #fff;
		font-weight: 800;
		// text-shadow: 1px 1px 2px #444;
		margin-top:  80px;
		margin-bottom: 20px;

		&.phone{
			@include breakpoint(landscape) {
				font-size: 1.75em;
			}
			span.block{
				display: block;
				@include breakpoint(landscape) {
					display: inline-block;
				}
			}
		}

		@include breakpoint(small only) { 
			font-size: 2.5rem;
			margin-top: 10px;
			line-height: 3.25rem;
			margin-bottom: 40px;

		}
		@include breakpoint(landscape) {
				margin-top: 20px;

			}


		
		text-align: center;
		span.caps{
			font-weight: 900;
			text-transform: uppercase;
		}
		span.block{
			@include breakpoint(medium only) {  
				
			}
			@include breakpoint(landscape) {
				
			}
		}
		
	}

	h2{
		

		font-size: 1.75rem;
		line-height: 2.75rem;
		// margin-top: 50px;
		
		
		font-family: 'Work Sans', sans-serif;
		font-weight: 600;
		
		
		text-align: center;
		
		color: #fff;

		span.sub-sub{
			font-family: 'Work Sans', sans-serif;
			font-weight: 900;
			font-size:3rem;
		}

		@include breakpoint(medium only) {
		
			font-size: 3rem;	
			margin-bottom: 50px;
			// margin-top: 30px;
		}
		
		// @include breakpoint(large) {
		// 	font-size: 2.75rem;		
		// 	margin-top: 30px;
		// }
	}

	h3{
		color: #fff;
		font-family: 'Eczar', serif;
		text-transform: uppercase;
		font-size: 1.25rem;
		font-weight: 700;
	}
 
	.phone{
		font-weight: 800;
		font-size: 2rem;
		a{color: white;}
		i{
			top: 4px;
		    position: relative;
		    font-size: 2.25rem;
		}
	}

	section#logo-section{
		top:30px;
		margin-bottom: 100px;
		position: relative;
		span.quote{
		    font-family: 'Work Sans',sans-serif;
		    position: absolute;
		    margin-left: -50px;
		    color: #fff;
		    font-size: 5rem;
		    margin-top: -40px;
		    @include breakpoint(small only) {  
			margin-left: -30px;	
			}

		}
		p{
			color: #fff;
			font-weight: 500;
			font-size: 1.5rem;
			text-align: left;
		}
		p#quoted{
			position: relative;
			@media screen and (min-width: 41.75em){	
				margin-top: 100px;
			}
			@include breakpoint(small only) {  
			margin-bottom:20px;
			}

		}
	}

	div#logo{
		text-align: center;
		img{
			width:550px;
			

		}
		img.phone{
			@include breakpoint(landscape) {
				width:250px;
				
			}
		}
				
	}

	
}

#sub-section{
	background: rgba(33,65,146,1);
		background-image: url(../../img/shared/brundage-sitework-metal.png);
}

#about-sub-heading{
			
	padding-bottom: 25px;
		
	


	
	

	

	section.about-cta{
		font-size: 2rem;

		@include breakpoint(medium up) { 
			margin-top:20px;
			a.secondary{
			margin-right: 50px;
			}
		}

		&.phone{
			margin-top:50px;

			@include breakpoint(landscape) {
				margin-top:-40px;	
			}
		}	
	}

}



#about-heading{
	@media screen and (min-width: 26em){	
		height: 100vh;
		}

	@media screen and (max-width: 62.3125em){	
		background: linear-gradient( rgba(0, 0, 0, $bg-tint-25), rgba(0, 0, 0, $bg-tint-25) ),url(../../img/about/corey-brundage-wellfeet-chamber.jpg);
		background-size: cover;
		background-position-x: right;
	}

	@media screen and (min-width: 62.3125em){	
		background: linear-gradient( rgba(0, 0, 0, $bg-tint-25), rgba(0, 0, 0, $bg-tint-25) ),url(../../img/about/corey-brundage.jpg);
		background-size: cover;
		background-position-x: right;
	}
	
	@include breakpoint(xxlarge up) { 
		height: 750px;
		h1{
			margin-top: 100px !important;
		}
	}

	@media screen and (min-width: 2100px) { 
		height: 880px;
		background-position-y: -50px;
		p{
			font-size: 2rem !important;
		}
	}

}	

#letter{
	margin-top: 30px;
	text-align: left !important;
	border-bottom: 2px solid $brundage-blue;
	p{
		color: $brundage-blue;
		text-align: left !important;
		span{
			margin-left:50%;
		}
	}

	h2{
		color: $brundage-blue;
		font-weight: 500;
	}

}

#about{
	margin-top: 30px;
	margin-bottom: 30px;
}

#call-today{
	@include breakpoint(medium up) { 
		margin-bottom: 30px;
	}
	@media screen and (max-width: 47em){	
	font-size: 2rem;
	}
	font-size: 2rem;
}

#call-today, #hrs{
	color: $brundage-blue;
	font-weight: 700;
}

#addy{
	color: $brundage-blue;
	font-weight: 700;
}

