//globals
body{
    background-repeat: repeat;
    background-image: url(../../img/shared/brundage-sitework-metal.png);
}


p{
    font-size: 1.125rem;
    font-family: 'Work Sans', sans-serif;
}

.m-0{
    margin:0px;
}

.p-0{
    padding: 0px;
}


.hours{
    text-align: center;

    h1, header h1{
        font-family: 'Work Sans', sans-serif;
        font-size: 1.5rem;
        color: $lin-winkle;
        padding-top: 25px;
    }
    p{
        font-family: 'Work Sans', sans-serif;
        font-style: italic;
        color: $lin-dkerblue;

    }
}

.hours-footer{
    padding-bottom: 10px;

}

footer{
    // background-color: #fff;
    // a:not(.wedding-button){

    //     &:visited{
    //        color:$lin-winkle;
    //     }
    // }
}

@include breakpoint(small only) {
    .hide-on-small{
        display: none;  
    }
}


.bhr-banner{
    padding: 5px;
    background-color: #008CBA;
    color: #fff;
    display: none; //so it wont show on linnell!!
    a{
        text-decoration: underline;
        color: #fff;
    }
}

a.ss-social{
    font-size: 1.5rem;
    top: 6px;
    position: relative;
}

.logo-svg-nav-lg{
    fill: $lin-winkle;
    stroke: $lin-winkle;
}

.wedding-button{
    color: #fff;
}

.request-info{
    margin-bottom:50px;
}

form{
    input[type=submit]{
        
            color: #fff;
            background-color: $lin-winkle;
            border:none;
            padding:8px;
            font-family: 'Work Sans', sans-serif;
            margin-bottom: 40px;

    
    }
    
    label{
        font-size: 1.125rem;
    }



    .heading, .heading label{
        font-size: 1.25rem;
        margin-top: 30px;
        font-weight: 700;
        color: #393b6b;

    }
    .instructions{
        font-size: 1rem;
        font-style: italic;
        font-weight: 100;
        color: #777;
    }
}


#thank-you{
    margin-top: 40px;
    margin-bottom: 40px;
    h1{
        font-family: 'Work Sans', sans-serif;
        text-align: center;

    }

    p{
      font-family: 'Work Sans', sans-serif;
    }

    a{
        color: $lin-winkle;
    }
}


.sitewide-banner{
    // background-color: $lin-winkle;
    // background-color: rgba( $lin-winkle, .25 );
    display: none !important;
    background-color: rgba( $lin-dkerblue, .75 );
    
    p{
        margin-bottom: 0;
        color: #fff;
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        a{
         color: #fff; 
         &:hover{text-decoration: underline;}
        }
    }

    .close-button{
        right: 1rem;
        top: -.125rem;
        font-size: 1.5em;
        line-height: 1;
        color: #fff;

    }
}

.easter-button{
    // background-color: $lin-winkle;
    // background-color: rgba( $lin-winkle, .25 );
    display: none;
    position: relative;
    top: 30px;
    background-color: rgba( $lin-winkle, 1 );
    
    padding: 10px;
        
        color: #fff;
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        
         font-size: 1.125rem;
        &:hover{
            color: #fff;
            opacity: .8;
        }
    

    .close-button{
        right: 1rem;
        top: -.125rem;
        font-size: 1.5em;
        line-height: 1;
        color: #fff;

    }
}

#easterMenu{
    text-align: center;
    h1, h2, h3, h4, h5, h6 {
        color: $lin-dkblue;
    }
    h1{
         font-family: 'Eczar', serif;
    }
    
    h2{
        font-family: 'Work Sans', sans-serif;
        // border-bottom: 1px solid;
        // display: block;
        margin-bottom: 30px;
    }

    h3{
        font-family: 'Work Sans', sans-serif;
        margin-top: 20px;
    }

    p{
         font-family: 'Eczar', serif;
    }

    .wedding-button{
        background-color: $lin-winkle;
    }
}




.xlarge-2 {
   @include breakpoint(xxlarge up) { 
      width:50%;
   }
}