/* Variables */

// _sitesNav2.html
.menu-text{
  // float: left;
  @media screen and (max-width: 39.9375em) {
    padding:0px;
  }

}

.floatL{
  float:left;
}

.w-100{width: 100px;}

.title-bar .menu-icon{
  z-index: 50;
  padding-bottom: 30px;

}

#main-menu{
  position: relative;
  @media screen and (min-width: 40em) {top: 15px;}

}

.is-accordion-submenu{
  padding-left: 10px;
}

ul[role=menubar]{

  // &:after, &:before {
  //     display: table;
  //     content: " ";
  // }

  // *, :after, :before {
  //     box-sizing: inherit;
  // }


  // &:after {
  //     clear: both;
  // }

  // &:after, &:before {
  //     display: table;
  //     content: " ";
  // }

  // *, :after, :before {
  //     box-sizing: inherit;
  // }
}


// Adjust width accordingly (use even #'s)
$logo-width: 70px;
// Reduce px value to increase space between logo and menu text
$logo-padding: $logo-width - 12px; 
// Leave alone
$logo-margin: - ($logo-width / 2);

/* Small Navigation */
.logo-small {
  // float: right;
  display: none;
}

.title-bar {
  padding: 0 .5rem;
  // height: 36px;  for fixed nav mobile
}

.title-bar .menu-icon{
  // position: fixed; for fixed nav mobile
  // right: 8px;  for fixed nav mobile
}

.title-bar:after{
  display: none;
}

.menu-icon:after{
  
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
    background-color:#cacaca;
    z-index: 100;

  
}

.menu-icon,
.title-bar-title {
  position: relative;
  top: 15px;
  float: right;
}

.title-bar{
  background:none;
}

.title-bar-title{
  display: none;
}


.menu-text {
  a{
    position: relative;
    z-index: 10;
  }
  h1{
  font-family: 'Eczar', serif;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 1.25rem !important;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  color: $brundage-blue;
  }
}

.menu.vertical>li{
  font-size: 1.5rem;
}

/* Medium-Up Navigation */
@media only screen and (min-width: 40rem) { 

  .logo-wrapper {
    position: relative;
    
    .logo {
      width: $logo-width;
      height:  92px;
      position: absolute;
      left: 50%;
      right: 50%;
      top: -4px;
      margin-left: $logo-margin;
      // margin-left: -35px;
      &:hover{
        opacity: .75;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  // Right part
  .top-bar-right {
    // width: 50%;
    // padding-left: $logo-padding;
    
    // ul {
    //   float: left;
    // }
  }
  
  // Left part
  .top-bar-left {
    // width:  50%;
    // padding-right: $logo-padding;
    
    // ul {
    //   float: right;
    // }
  }

  .top-bar-right, .top-bar-left{
    // margin-top: 10px;
  }

  //asa's override
  .top-bar .top-bar-left, .top-bar .top-bar-right {
    // width: 50%;
  }
}

.top-bar .menu>li>a{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}


.sticky-topbar {
  width: 100%;
  z-index: 5;
  background: #fff;
  background-image: url(../../img/shared/brundage-sitework-metal.png);
}

.top-bar{
    // padding: .75rem;
}

// .top-bar ul li{
ul#main-menu {

  ul.is-dropdown-submenu{
    background: $brundage-blue;
  }



  li{
    font-family: 'Eczar', serif;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 600;
    

    a{
        color:#b3afaf;

        // color: #D3D3D3;

        &:hover{
            color: $brundage-blue;
            // border-bottom: 1px solid #807FFE;
            transition: all 0.2s ease-in-out;

        }
    }
  }
  li.highlight{
    a span{
      border-bottom: 3px solid $brundage-blue;
      color: $brundage-blue;
      &:hover{
        color: rgba($brundage-blue, .5);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  li.is-submenu-item{
    a span{
      color: #b3afaf;
      border-bottom: 0px;
      &:hover{
        color: rgba(#b3afaf, .5);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  li.is-submenu-item.highlight{
    a span{
    color: #fff;
    text-decoration: underline;
    }
  }
  li.is-accordion-submenu-item.highlight{
    a span{
    color: $brundage-blue;
    text-decoration: underline;
    }
  }

}






.top-bar{
  top: 0px !important;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a:after{
  border-top-color: #b3afaf;
}

.dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a:after, .is-accordion-submenu-parent>a:after{
  border-color: $brundage-blue transparent transparent;


}

