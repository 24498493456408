footer{
	margin-top: 30px;
	color: #fff;
	padding-top: 30px;
	padding-bottom: 30px;
	// background-image: url(../../img/shared/footer-bg2.png);
	// background-repeat: repeat;
	// position: relative;
	background-color: rgba($brundage-blue, .75);

    // background-repeat: repeat;
    // background-image: url(../../img/shared/brundag-esitework-metal.png);


	.normal, .normal a{
		color:#999;
	}

	.normal a{
		&:hover{
					color: #fff !important;	
		text-decoration: none;
		transition: all 0.2s ease-in-out;
		}
	}

	p, a, p a, a p, a#addy, #hrs, h4{
		font-family: 'Eczar', serif;
  
  text-transform: uppercase;
  color: #fff;

	}
	h4{
		@include breakpoint(small only) {
			font-size: 1.5rem;
		}
		padding-top: 20px;
	}
	a, p a, a p, i.fa{
		&:hover{
		color: #999 !important;	
		text-decoration: none;
		transition: all 0.2s ease-in-out;
	}
	}

	a#addy{
		font-weight: 400;
		&:hover{
		color: #999 !important;	
		// text-decoration: underline;
		transition: all 0.2s ease-in-out;
		}
	}
	a:hover{
		color: #999 !important;	
		// text-decoration: underline;
		transition: all 0.2s ease-in-out;
	}

	.row{
    	// background-color: #fff;
	}

	.fa{
		color: #fff;
	}

	div#art{
		// margin-top: -20px;
		
	//// style 1:
		// margin-top: -235px;
		// position: absolute;
		// z-index: -1;

		position: absolute;
	    // z-index: -1;
	    // bottom: 120px;
	    bottom: 278px;
	    @include breakpoint(small only) {
	    	bottom:350px;
	    }
	    // @include breakpoint(medium) {
	    // 	bottom:200px;
	    // }
	    // bottom: 180px;
	    opacity: .5;
	}

	p#copyright{
		// margin-top: 60px;
		margin-bottom: 20px;
	}

	p{
		margin-bottom: 0px;
	}

	.column-block{
		margin-bottom: 1rem;
	}

	#social-row{
		// margin-bottom: 35px;
		// margin-top: 60px;
	}

	.footer-links{
		position: relative;
		z-index: 1;
	}

	span#footer-home{
		font-size: .75rem;
	}
	a#home-footer-link{
		border-bottom: 1px solid #fff;
		margin-bottom: 5px;
	}
}
